import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  AllLeaves: [],
  leaveTotal: [],
  leaveVacation: [],
  leaveEmpPending: [],
  getMyLeaves: [],
  userBalance: 0,
  unreadLeave: {},
  applyForNegotiationStatus: "idle",
  applyForNegotiationError: null,

  getMyLeavesStatus: "idle",
  getMyLeavesError: null,

  leaveEmpPendingStatus: "idle",
  leaveEmpPendingError: null,

  leaveVacationStatus: "idle",
  leaveVacationError: null,

  leaveTotalStatus: "idle",
  leaveTotalError: null,

  addLeaveStatus: "idle",
  addLeaveError: null,

  status: "idle",
  error: null,

  approveRequestStatus: "idle",
  approveRequestSuccess: "",
  approveRequestError: "",

  rejectRequestStatus: "idle",
  rejectRequestSuccess: "",
  rejectRequestError: "",

  approveNegotiationStatus: "idle",
  approveNegotiationSuccess: "",
  approveNegotiationError: "",

  rejectNegotiationStatus: "idle",
  rejectNegotiationSuccess: "",
  rejectNegotiationError: "",

  fetchUserBalanceStatus: "idle",
  fetchUserBalanceSuccess: "",
  fetchUserBalanceError: "",
};

export const applyForNegotiation = createAsyncThunk(
  "leave/applyForNegotiation",
  async (body, thunkAPI) => {
    const {
      id,
      negotiationStartDate,
      negotiationEndDate,
      negotiationDescription,
    } = body;

    let data;
    try {
      const response = await axios.patch(
        `/leaves/negotiation/${id}
    `,
        {
          negotiationStartDate,
          negotiationEndDate,
          negotiationDescription,
        }
      );
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllLeaves());

        return {
          data: data,
          message: "Negotiation request have been submitted!",
        };
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const acceptNegotiation = createAsyncThunk(
  "leave/acceptNegotiation",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/leaves/accept-negotiation/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getMyLeaves());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const rejectNegotiation = createAsyncThunk(
  "leave/rejectNegotiation",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/leaves/reject-negotiation/${id}`);

      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(getMyLeaves());

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const editLeaveCurrentUser = createAsyncThunk(
  "AllLeaves/editLeaveCurrentUser",
  async (body, thunkAPI) => {
    let data;
    const { description, type, dateStart, dateEnd } = body.data;
    try {
      const response = await axios.patch(`/leaves/edit/${body.id}`, {
        description,
        type,
        dateStart,
        dateEnd,
      });
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getMyLeaves());
        return {
          data: data,
          message: "Your leave request has been updated successfully.",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const getAllLeaves = createAsyncThunk(
  "AllLeaves/getAllLeaves",
  async () => {
    let data;
    try {
      const response = await axios.get(`/leaves`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const getMyLeaves = createAsyncThunk(
  "leaveTotal/getMyLeaves",
  async () => {
    let data;
    try {
      const response = await axios.get(`/leaves/my-leaves`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const addLeave = createAsyncThunk(
  "leaveTotal/addLeave",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/leaves`, body);

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getMyLeaves());
        return {
          data: data,
          message: "Your leave request is successfully submited ! ",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const fetchAllLeave = createAsyncThunk(
  "leaves/fetchAllLeave",
  async () => {
    let data;
    try {
      const response = await axios.get(`/leaves/requests`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const fetchEmpInVacation = createAsyncThunk(
  "leaves/fetchEmpInVacation",
  async () => {
    let data;
    try {
      const response = await axios.get(`/leaves/employees-vacation`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const fetchEmppending = createAsyncThunk(
  "leaves/fetchEmppending",
  async () => {
    let data;
    try {
      const response = await axios.get(`/leaves/pending`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const approveLeaveRequest = createAsyncThunk(
  "leave/approveLeaveRequest",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/leaves/approve/${id}
    `);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllLeaves());
        return data.message;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const rejectLeaveRequest = createAsyncThunk(
  "leave/rejectLeaveRequest",
  async (data, thunkAPI) => {
    const { id, descriptionReject } = data;

    try {
      const response = await axios.patch(
        `/leaves/reject/${id}
    `,

        { descriptionReject: descriptionReject }
      );
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllLeaves());

        return data.message;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);
export const negotiateLeaveRequest = createAsyncThunk(
  "leave/negotiateLeaveRequest",
  async (body, thunkAPI) => {
    const { id, startDate, endDate, description } = body;

    let data;
    try {
      const response = await axios.patch(
        `/leaves/negotiation/${id}
    `,
        { startDate: startDate, endDate: endDate, description: description }
      );
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllLeaves());
        return data.message;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const fetchUserBalance = createAsyncThunk(
  "leave/fetchUserBalance",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`/leaves/balance/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const deleteLeave = createAsyncThunk(
  "leave/deleteLeave",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/leaves/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        const state = thunkAPI.getState();
        const { code } = state.user.fetchMe?.role;
        code === "SUPER ADMIN" ?
           thunkAPI.dispatch(getAllLeaves())
         : 
          thunkAPI.dispatch(getMyLeaves());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

const slice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    handleNewLeave: (state, action) => {
      state.AllLeaves.push(action.payload.newLeave);
    },
  },
  extraReducers: {
    [getAllLeaves.pending]: (state) => {
      state.status = "loading";
    },
    [getAllLeaves.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.AllLeaves = action.payload;
    },
    [getAllLeaves.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [getMyLeaves.pending]: (state) => {
      state.getMyLeavesStatus = "loading";
    },
    [getMyLeaves.fulfilled]: (state, action) => {
      state.getMyLeavesStatus = "succeeded";
      state.getMyLeaves = action.payload;
    },
    [getMyLeaves.rejected]: (state, action) => {
      state.getMyLeavesStatus = "failed";
      state.getMyLeavesError = action.payload;
    },

    [fetchAllLeave.pending]: (state) => {
      state.leaveTotalStatus = "loading";
    },
    [fetchAllLeave.fulfilled]: (state, action) => {
      state.leaveTotalStatus = "leaveTotalStatus";
      state.leaveTotal = action.payload;
    },
    [fetchAllLeave.rejected]: (state, action) => {
      state.leaveTotalStatus = "failed";
      state.leaveTotalError = action.payload;
    },

    [fetchEmpInVacation.pending]: (state) => {
      state.leaveVacationStatus = "loading";
    },

    [fetchEmpInVacation.fulfilled]: (state, action) => {
      state.leaveVacationStatus = "succeeded";
      state.leaveVacation = action?.payload;
    },

    [fetchEmpInVacation.rejected]: (state, action) => {
      state.leaveVacationStatus = "failed";
      state.leaveVacationError = action.error;
    },

    [fetchEmppending.pending]: (state) => {
      state.leaveEmpPendingStatus = "loading";
    },

    [fetchEmppending.fulfilled]: (state, action) => {
      state.leaveEmpPendingStatus = "succeeded";
      state.leaveEmpPending = action.payload;
    },

    [fetchEmppending.rejected]: (state, action) => {
      state.leaveEmpPendingError = "failed";
      state.error = action.payload;
    },
    [approveLeaveRequest.pending]: (state) => {
      state.approveRequestStatus = "loading";
    },

    [approveLeaveRequest.fulfilled]: (state, action) => {
      state.approveRequestStatus = "succeeded";
      state.approveRequestSuccess = action.payload;
    },

    [approveLeaveRequest.rejected]: (state, action) => {
      state.approveRequestStatus = "failed";
      state.approveRequestError = action.payload;
    },
    [rejectLeaveRequest.pending]: (state) => {
      state.rejectRequestStatus = "loading";
    },

    [rejectLeaveRequest.fulfilled]: (state, action) => {
      state.rejectRequestStatus = "succeeded";
      state.rejectRequestSuccess = action.payload;
    },

    [rejectLeaveRequest.rejected]: (state, action) => {
      state.rejectRequestStatus = "failed";
      state.rejectRequestError = action.payload;
    },
    [acceptNegotiation.pending]: (state) => {
      state.approveNegotiationStatus = "loading";
    },

    [acceptNegotiation.fulfilled]: (state, action) => {
      state.approveNegotiationStatus = "succeeded";
      state.approveNegotiationSuccess = action.payload;
    },

    [acceptNegotiation.rejected]: (state, action) => {
      state.approveNegotiationStatus = "failed";
      state.approveNegotiationError = action.error;
    },
    [rejectNegotiation.pending]: (state) => {
      state.rejectNegotiationStatus = "loading";
    },

    [rejectNegotiation.fulfilled]: (state, action) => {
      state.rejectNegotiationStatus = "succeeded";
      state.rejectNegotiationSuccess = action.payload;
    },

    [rejectNegotiation.rejected]: (state, action) => {
      state.rejectNegotiationStatus = "failed";
      state.rejectNegotiationError = action.error;
    },

    [addLeave.pending]: (state) => {
      state.addLeaveStatus = "loading";
    },
    [addLeave.fulfilled]: (state, action) => {
      state.addLeaveStatus = "succeeded";
    },
    [addLeave.rejected]: (state, action) => {
      state.addLeaveStatus = "failed";
      state.addLeaveError = action.error;
    },

    [applyForNegotiation.pending]: (state) => {
      state.applyForNegotiationStatus = "loading";
    },
    [applyForNegotiation.fulfilled]: (state) => {
      state.applyForNegotiationStatus = "succeeded";
    },
    [applyForNegotiation.rejected]: (state, action) => {
      state.applyForNegotiationStatus = "failed";
      state.applyForNegotiationError = action.error;
    },
    [fetchUserBalance.pending]: (state) => {
      state.fetchUserBalanceStatus = "loading";
    },
    [fetchUserBalance.fulfilled]: (state, action) => {
      state.fetchUserBalanceStatus = "succeeded";
      state.userBalance = action.payload.balance;
    },
    [fetchUserBalance.rejected]: (state, action) => {
      state.fetchUserBalanceStatus = "failed";
      state.fetchUserBalanceError = action.error;
    },
  },
});
export const { handleNewLeave } = slice.actions;

export const reducer = slice.reducer;

export default slice;
